// Bootstrap brand color customization

/*     brand Colors              */
/* Defualt:
$brand-primary:              $purple-500 !default;
$brand-info:                 $cyan-500 !default;
$brand-success:              $green-500 !default;
$brand-warning:              $orange-500 !default;
$brand-danger:               $red-500 !default;
$brand-rose:                 $pink-500 !default;
$brand-inverse:              $black-color !default;
*/


$brand-primary:              #01153F !default;
$brand-info:                 #464655 !default;
$brand-success:              #294936 !default;
$brand-warning:              #F18F01 !default;
$brand-danger:               #7E0900 !default;
$brand-rose:                 #5D6A84 !default;
$brand-inverse:              #000000 !default;
